import React from 'react';

import styled, {keyframes} from "styled-components";
import {fadeIn} from "react-animations";

const SlideIn = styled.div`
  animation: 0.4s ${keyframes`${fadeIn}`};
`;

const SlideAnimation = ({children, className="", style}) => {
    return (
        <SlideIn className={className} style={style}>
            {children}
        </SlideIn>
    );
};

export default SlideAnimation;
