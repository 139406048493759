import React, {useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import AgencyItem from "./AgencyItem";
import {RadioGroup} from "@mui/material";
import SlideAnimation from "./SlideAnimation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAnchor, faBuilding, faHome, faPlaneDeparture, faShip} from "@fortawesome/free-solid-svg-icons";
import 'react-perfect-scrollbar/dist/css/styles.css';

const AgencyPicker = ({agencies, selected, name, onChange, checkColor}) => {


    const [displayedAgencies, setDisplayedAgencies] = useState([])
    const [nbLines, setNbLines] = useState(1)

    const [state, setState] = useState({
        value: selected
    });

    const [agencyGroups, setAgencyGroups] = useState([])

    useEffect(() => {
        if(agencies) {
            const availableAgencies = agencies?.filter(item => item.enable_on_website === true)
            let sorted = availableAgencies.sort((item1,item2) =>  {
                if(!item1.is_default && item2.is_default) {
                    return 1
                } else if(item1.is_default && !item2.is_default) {
                    return -1
                }
                return 0
            })
            setDisplayedAgencies(sorted)
            setNbLines(Math.ceil(availableAgencies.length/3))


            const newGroups = {
                0: {agencies: [], position: 9999}
            }
            agencies.forEach(agency => {
                if(agency.group) {
                    if(newGroups?.[agency.group.id]) {
                        newGroups[agency.group.id].agencies.push(agency)
                    } else {
                        newGroups[agency.group.id] = {...agency.group, agencies: [agency]}
                    }
                } else {
                    newGroups[0].agencies.push(agency)
                }
            })
            const sortedGroups = Object.values(newGroups)?.sort( (item1, item2) => {
                if(item1?.position > item2?.position) {
                    return 1
                } else if (item1?.position < item2?.position) {
                    return -1
                } else {
                    return 0
                }
            })
            // sorting agencies in each group in alphabetical 
            Object.values(newGroups).forEach(group => {
                group.agencies.sort((a, b) => a.name.localeCompare(b.name));
            });
            setAgencyGroups(sortedGroups)
        }
    }, [agencies]);

    const handleAgencyChange = (value) => {
        setState(prevState => {
            return {...prevState, value: value}
        })
        onChange && onChange({name, value})
    }
    const handleAgencyItemClick = (agency) => {
        onChange && onChange({name, value: agency?.id})
    }

    return (
        <SlideAnimation style={{minHeight: '500px'}}>
            {/*<PerfectScrollbar options={{suppressScrollX: true}} className={"h-100"}>*/}
                <RadioGroup
                    aria-label={name}
                    name={name}
                    value={state.value}
                    onChange={(e) => handleAgencyChange(e.target.value)}
                >
                    <Row>
                        {agencyGroups?.filter((item) => item?.position !== 9999).map( agencyGroup => {
                            return <Col key={`agency-group-${agencyGroup.id}`} md={4} key={`agency-group-item-${agencyGroup?.id}`} className={"mb-3"}>
                                <p className={"bold c-main"}>
                                    {agencyGroup?.icon === "plane" && (<FontAwesomeIcon className={"me-1"} icon={faPlaneDeparture} />)}
                                    {agencyGroup?.icon === "boat" && (<FontAwesomeIcon className={"me-1"} icon={faShip} />)}
                                    {agencyGroup?.icon === "anchor" && (<FontAwesomeIcon className={"me-1"} icon={faAnchor} />)}
                                    {agencyGroup?.icon === "building" && (<FontAwesomeIcon className={"me-1"} icon={faBuilding} />)}
                                    {agencyGroup?.icon === "house" && (<FontAwesomeIcon className={"me-1"} icon={faHome} />)}
                                    <span>{agencyGroup?.title }</span>
                                </p>
                                <hr className={"my-2"}/>
                                <div className={"d-flex flex-column align-items-center"}>
                                    {agencyGroup?.agencies?.map(agency => {
                                       return <AgencyItem key={`agency-item-${agency.id}`} agency={agency} onClick={handleAgencyItemClick} color={checkColor}/>
                                    })}
                                </div>
                            </Col>
                        })}
                        {/*<Col md={4} className={"d-flex flex-column"}>*/}
                        {/*    {displayedAgencies*/}
                        {/*        .filter((item,index) => index < nbLines)*/}
                        {/*        .map(agency => {*/}
                        {/*            return <AgencyItem key={`agency-item-${agency.id}`} agency={agency} onClick={handleAgencyItemClick} color={checkColor}/>*/}
                        {/*            // <p><AgencyName agency={agency} /></p>*/}
                        {/*        })*/}
                        {/*    }*/}
                        {/*</Col>*/}
                        {/*<Col md={4}>*/}
                        {/*    {displayedAgencies*/}
                        {/*        .filter((item,index) => (index >= nbLines && index < nbLines*2 ))*/}
                        {/*        .map(agency => {*/}
                        {/*            return <AgencyItem key={`agency-item-${agency.id}`} agency={agency} onClick={handleAgencyItemClick} color={checkColor}/>*/}
                        {/*        })*/}
                        {/*    }*/}
                        {/*</Col>*/}
                        {/*<Col md={4}>*/}
                        {/*    {displayedAgencies*/}
                        {/*        .filter((item,index) => (index > nbLines*2 ))*/}
                        {/*        .map(agency => {*/}
                        {/*            return <AgencyItem key={`agency-item-${agency.id}`} agency={agency} onClick={handleAgencyItemClick} color={checkColor}/>*/}
                        {/*        })*/}
                        {/*    }*/}
                        {/*</Col>*/}
                    </Row>
                    <hr />
                    <Row>
                        {agencyGroups?.filter((item) => item?.position === 9999).map( agencyGroup => {
                            return <div key={`ungrouped-agency`}>
                                {agencyGroup?.agencies?.map(agency => {
                                    return <Col key={`ungrouped-agency-${agency.id}`} md={4}>
                                        <div className={"d-flex flex-column align-items-center"}>
                                            <AgencyItem
                                                key={`agency-item-${agency.id}`}
                                                agency={agency}
                                                onClick={handleAgencyItemClick}
                                                color={checkColor}
                                            />
                                        </div>
                                    </Col>
                                })}
                            </div>
                        })}
                    </Row>
                </RadioGroup>
            {/*</PerfectScrollbar>*/}
        </SlideAnimation>
    );
};

export default AgencyPicker;
