import React from 'react';
import {FormControlLabel, Radio} from "@mui/material";
import AgencyName from "../../components/AgencyName/AgencyName";
import cx from "classnames"

const AgencyItem = ({agency, name, onClick, color = "#000", className= ""}) => {
    return (
        <FormControlLabel
            className={cx("border-bottom w-100 me-0",className)}
            value={agency.id}
            onClick={() => onClick && onClick(agency)}
            control={<Radio
                name={name}
                size="small"
                className={"p-1"}
                sx={{
                    '&.Mui-checked': {
                        color: color,
                    },
                }}
            />}
            label={<AgencyName className={'fs12'} agency={agency}/>}
        />
    );
};

export default AgencyItem;
