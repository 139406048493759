import React, {useEffect, useMemo, useState} from 'react';
import SlideAnimation from "./SlideAnimation";
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from "moment";
import {START_DATE, END_DATE, VERTICAL_ORIENTATION, HORIZONTAL_ORIENTATION} from "react-dates/constants"
import 'react-dates/initialize';

import "../../../sass/components/react-dates-overrides.scss"
import ResizeDetector from "react-resize-detector";

moment.locale('fr')
const pickerMode = {
    start : "start",
    end : "end"
}

const DatePicker = ({mode, startDateStr, endDateStr, onSelect, name, minDate, maxDate, unavailableDays = []}) => {

    const [state, setState] = useState({
        start_date : moment(startDateStr, 'YYYY-MM-DD').isValid() ? moment(startDateStr, 'YYYY-MM-DD') : null,
        end_date: null,
        focused : START_DATE
    });

    const pickerMinDate = useMemo(() => {
        if(minDate) {
            return minDate
        } else {
            return (mode === pickerMode.end && state.start_date) ? state.start_date : moment()
        }
    }, [minDate]);

    const pickerMaxDate = useMemo(() => {
        if(maxDate) {
            return maxDate
        } else {
            return (mode === pickerMode.start && state.end_date) ? state.end_date : null
        }
    }, [maxDate]);

    useEffect(() => {
        setState(prevState => {
            const newState = {...prevState}
            if(startDateStr) {
                const momentStart = moment(startDateStr, 'YYYY-MM-DD')
                if(momentStart.isValid()) {
                    newState.start_date = momentStart
                }
            }
            if( endDateStr) {
                const momentEnd = moment(endDateStr,'YYYY-MM-DD')
                if(momentEnd.isValid()) {
                    newState.end_date = momentEnd
                }
            }
            return newState
        })
    }, [startDateStr, endDateStr]);

    useEffect(() => {
        if(mode === pickerMode.end) {
            setState(prevState =>({
                ...prevState, focused: END_DATE
            }))
        } else {
            setState(prevState =>({
                ...prevState, focused: START_DATE
            }))
        }
    }, [mode])

    const handleChange = ({startDate, endDate}) => {
        console.log(state.focused, startDate, endDate)
        setState(prevState => ({
            ...prevState,
            start_date: startDate,
            end_date: endDate
        }))
        if(mode === pickerMode.start) {
            onSelect && onSelect({name, value: startDate.format("YYYY-MM-DD") })
        } else {
            onSelect && onSelect({name, value: endDate.format("YYYY-MM-DD") })
        }
    }

    const handleInitialMonth = () => {
        if (state.start_date) {
           return state.start_date
        }
        return moment()
    }

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => {
                return (
                    <SlideAnimation className={"d-flex justify-content-center align-items-center h-100"}>
                        <DayPickerRangeController
                            noBorder
                            hideKeyboardShortcutsPanel
                            orientation={width > 600 ? HORIZONTAL_ORIENTATION : VERTICAL_ORIENTATION}
                            numberOfMonths={2}
                            minDate={pickerMinDate}
                            maxDate={pickerMaxDate}
                            startDate={state.start_date}
                            endDate={state.end_date}
                            onDatesChange={handleChange}
                            focusedInput={state.focused}
                            minimumNights={0}
                            initialVisibleMonth={handleInitialMonth}
                            isDayBlocked={(date) => {
                                let blocked = false
                                const dateStr = date.format('YYYY-MM-DD')
                                const nowStr = moment().startOf('day').format('YYYY-MM-DD')
                                const isToday = moment().format('YYYY-MM-DD') === dateStr
                                if(mode === pickerMode.end && state.start_date) {
                                    blocked = dateStr < moment(state.start_date).format('YYYY-MM-DD')
                                }
                                if (unavailableDays?.length > 0 && unavailableDays.includes(Number(date.format('d')))){
                                    blocked = true;
                                }
                                blocked = blocked || dateStr < nowStr && !isToday
                                return blocked
                            }}
                        />
                    </SlideAnimation>
                )
            }}
        />
    );
};

export default DatePicker;
